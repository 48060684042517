import React from "react"

export default ({ title, subtitle, children, full, last }) => (
  <>
    <section
      className={`relative bg-white p-6 border ${
        full ? "max-w-4xl mx-auto" : "max-w-2xl mx-auto"
      }`}
    >
      {title && (
        <h2 className="mx-auto text-2xl font-semibold text-black font-heading">
          {title}
        </h2>
      )}
      {subtitle && <p className="mx-auto mb-2 text-sm">{subtitle}</p>}
      <div>{children}</div>
    </section>
    {!last ? (
      <div className="relative z-10">
        <div className="w-1 h-10 bg-primary-main mx-auto relative z-10"></div>
        <div
          className="w-1 mx-auto bg-primary-main left-0 right-0 top-full absolute z-90"
          style={{ height: "0.5px" }}
        ></div>
      </div>
    ) : null}
  </>
)
