import React from "react"
import Section from "../components/Section"

export default () => (
  <Section title="About Me 👋">
    <p className="mb-6">
      I'm a software engineer based in New York working on{" "}
      <a
        className="underline transition-all duration-200  font-semibold hover:text-primary-main"
        href="https://useavenue.com/"
      >
        Avenue
      </a>
      .
    </p>
    <p className="mb-6">
      I used to work at{" "}
      <a
        className="underline transition-all duration-200  font-semibold hover:text-primary-main"
        href="https://developer.amazon.com/en-US/alexa/science/"
      >
        Amazon
      </a>{" "}
      in Cambridge, MA, where I built software for the Alexa AI team focused on
      low-latency entity resolution.
    </p>
    <p className="mb-6">
      Prior to that, I went to school at UPenn as part of the Jerome Fisher
      Management &amp; Technology program. My favorite classes were{" "}
      <a
        className="underline transition-all duration-200  font-semibold hover:text-primary-main"
        href="https://www.cis.upenn.edu/~cis455/"
      >
        Internet &amp; Web Systems
      </a>{" "}
      and{" "}
      <a
        className="underline transition-all duration-200  font-semibold hover:text-primary-main"
        href="https://apps.wharton.upenn.edu/syllabi/2015C/LGST206409/"
      >
        Negotiations
      </a>
      .
    </p>
    <p className="mb-6">
      I grew up in New York and Geneva, Switzerland. I have fond memories of
      both places.
    </p>
  </Section>
)
