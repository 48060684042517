import React from "react"
import Section from "../components/Section"

import Img from "gatsby-image"
import SocialMedia from "../components/SocialMedia"
import { useStaticQuery } from "gatsby"
import { graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      profile_pic: file(relativePath: { eq: "jeffbarg.jpg" }) {
        childImageSharp {
          fixed(width: 96, height: 96, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Section>
      <div className="flex justify-between items-center">
        <div>
          <h1 className="mb-2 text-4xl font-semibold text-black font-heading">
            Jeff Barg
          </h1>
          <div className="">
            <SocialMedia />
          </div>
        </div>
        <div>
          <a href="https://twitter.com/jeffbarg" className="inline-flex">
            <Img
              draggable={false}
              className="transition-all duration-200 transform border shadow-sm select-none focus:scale-105 hover:scale-105"
              fixed={data.profile_pic.childImageSharp.fixed}
            />
          </a>
        </div>
      </div>
    </Section>
  )
}
